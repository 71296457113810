/*
 * @Autor: syq
 * @Date: 2021-07-15 21:56:56
 */
import request from "../../request/request";
import { stringify } from "qs";
//根据查询字段列表查询商品
const queryMarketCount = async (params) =>
  request({
    url: `/cartLine/getCartCount?${stringify(params)}`,
    method: "get",
  });
export { queryMarketCount };
