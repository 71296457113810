<!--
 * @Autor: syq
 * @Date: 2021-07-09 21:11:27
-->
<template>
  <div id="layout">
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="tabbar-content">
      <Tabbar :count="count"></Tabbar>
    </div>
  </div>
</template>

<script>
import Tabbar from "../../components/Tabbar.vue";
import { queryMarketCount } from "./service";
import { Toast } from "vant";
export default {
  components: { Tabbar },

  data() {
    return {
      count: 0,
    };
  },

  methods: {
    async getMarketCount() {
      const res = await queryMarketCount();
      if (res.status === "0") {
        this.count = res.data;
      }else{
        Toast.fail(res.msg)
      }
    },
  },

  created() {
    if(this.$route.path !== "/yvlan" 
    &&this.$route.path !== "/yvlanstore" 
    &&this.$route.path !== "/yvlantheme" 
    &&this.$route.path !== "/yvlanstoretheme" ){
      this.getMarketCount();
    }
    
  },
};
</script>

<style lang="less" scoped>
#layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
  }
  .tabbar-content {
    height: 50px;
  }
}
</style>
