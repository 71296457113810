<!--
 * @Autor: syq
 * @Date: 2021-07-09 17:31:23
-->
<template>
  <div class="tabbar">
    <van-tabbar
      v-model="active"
      inactive-color="#767676"
      active-color="#0095DA"
    >
      <van-tabbar-item
        name="Home"
        :icon="selectIcon(active, 'Home')"
        to="/home"
      >
        <span @click="clickTrackEvent('点击首页')">首页</span>
      </van-tabbar-item>
      <van-tabbar-item
        name="Classification"
        :icon="selectIcon(active, 'Classification')"
        to="/classification"
        >
        <span @click="clickTrackEvent('点击分类')">分类</span>
        </van-tabbar-item
      >
      <van-tabbar-item
        name="Stores"
        :icon="selectIcon(active, 'Stores')"
        to="/stores"
        ><span @click="clickTrackEvent('点击店铺')">店铺</span></van-tabbar-item
      >
      <van-tabbar-item
        v-if="count"
        name="Market"
        :icon="selectIcon(active, 'Market')"
        to="/market"
        :badge="count"
      >
        <span @click="clickTrackEvent('点击购物车')">购物车</span>
      </van-tabbar-item>
      <van-tabbar-item
        v-if="!count"
        name="Market"
        :icon="selectIcon(active, 'Market')"
        to="/market"
      >
        <span @click="clickTrackEvent('点击购物车')">购物车</span>
      </van-tabbar-item>
      <van-tabbar-item
        name="Mine"
        :icon="selectIcon(active, 'Mine')"
        to="/mine"
      >
        <span @click="clickTrackEvent('点击我的')">我的</span>
        
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import home0 from "../assets/icon/tab_icon_home_tails@2x.png";
import home1 from "../assets/icon/tab_icon_home_heads@2x.png";
import classification0 from "../assets/icon/tab_icon_classification_tails@2x.png";
import classification1 from "../assets/icon/tab_icon_classification_Heads@2x.png";
import shoppingbox0 from "../assets/icon/tab_icon_shoppingbox_tails@2x.png";
import shoppingbox1 from "../assets/icon/tab_icon_shoppingbox_heads@2x.png";
import store0 from "../assets/icon/tab_icon_store_tails@2x.png";
import store1 from "../assets/icon/tab_icon_store_heads@2x.png";
import me0 from "../assets/icon/tab_icon_me_tails@2x.png";
import me1 from "../assets/icon/tab_icon_me_heads@2x.png";
import { trackEvent } from '../utils/utils'

export default {
  props: {
    count: Number,
  },
  data() {
    const tabbarList = {
      Home: {
        defaultIcon: home0,
        selectIcon: home1,
      },
      Classification: {
        defaultIcon: classification0,
        selectIcon: classification1,
      },
      Market: {
        defaultIcon: shoppingbox0,
        selectIcon: shoppingbox1,
      },
      Stores: {
        defaultIcon: store0,
        selectIcon: store1,
      },
      Mine: {
        defaultIcon: me0,
        selectIcon: me1,
      },
    };
    return {
      active: this.$route.name,
      tabbarList,
    };
  },
  methods: {
    selectIcon(active, name) {
      if (active === name) {
        return this.tabbarList[name].selectIcon;
      }
      return this.tabbarList[name].defaultIcon;
    },
    clickTrackEvent(event) {
      trackEvent(null, event)
    }
  },
};
</script>

<style lang="less" scoped>
.tabbar {
  width: 100%;

  .van-tabbar {
    height: 50px;
  }

  /deep/ .van-icon__image {
    height: 22px;
  }
}
</style>
